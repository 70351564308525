import http from "@/utils/request"

export default {
  upload: {
    url: '/common/upload',
    name: "上传图片",
    post: async function(params){
      const formData = new FormData()
      formData.append('Photo', params.file)
      return await http.post(this.url, formData);
    }
  },
  getSet: {
    url: '/common/getSet',
    name: "获取设置",
    post: async function(params){
      return await http.get(this.url, params);
    }
  },
  getSets: {
    url: '/common/getSets',
    name: "获取多个设置",
    post: async function(params){
      return await http.get(this.url, params);
    }
  },
  getInformation: {
    url: '/api/getInformation',
    name: "获取网站信息",
    post: async function(params){
      return await http.post(this.url, params);
    }
  },
  captcha: {
    url: '/common/captcha',
    name: "获取验证码",
    post: async function(params){
      return await http.post(this.url, params);
    }
  },
  userInfo: {
    url: '/api/userInfo',
    name: "用户信息",
    post: async function(params){
      return await http.post(this.url, params);
    }
  },
  login: {
    url: '/api/login',
    name: "登录",
    post: async function(params){
      return await http.post(this.url, params);
    }
  },
  register: {
    url: '/api/register',
    name: "注册",
    post: async function(params){
      return await http.post(this.url, params);
    }
  },
  bannerList: {
    url: '/api/bannerList',
    name: "Banner",
    post: async function(params){
      return await http.post(this.url, params);
    }
  },
  goodsCategoryList: {
    url: '/api/goodsCategoryList',
    name: "类别列表",
    post: async function(params){
      return await http.post(this.url, params);
    }
  },
  goodsList: {
    url: '/api/goodsList',
    name: "商品列表",
    post: async function(params){
      return await http.post(this.url, params);
    }
  },
  addGoodsCollect: {
    url: '/api/addGoodsCollect',
    name: "收藏商品",
    post: async function(params){
      return await http.post(this.url, params);
    }
  },
  goodsCollectList: {
    url: '/api/goodsCollectList',
    name: "收藏列表",
    post: async function(params){
      return await http.post(this.url, params);
    }
  },
  goodsDetail: {
    url: '/api/goodsDetail',
    name: "商品详情",
    post: async function(params){
      return await http.post(this.url, params);
    }
  },
  sendMsg: {
    url: '/api/sendMsg',
    name: "发送验证码",
    post: async function(params){
      return await http.post(this.url, params);
    }
  },
  updatePhone: {
    url: '/api/updatePhone',
    name: "修改手机号",
    post: async function(params){
      return await http.post(this.url, params);
    }
  },
  forgetPwd: {
    url: '/api/forgetPwd',
    name: "忘记密码",
    post: async function(params){
      return await http.post(this.url, params);
    }
  },
  vipMoney: {
    url: '/api/vipMoney',
    name: "vip列表",
    post: async function(params){
      return await http.post(this.url, params);
    }
  },
  buyVip: {
    url: '/api/buyVip',
    name: "购买vip",
    post: async function(params){
      return await http.post(this.url, params);
    }
  },
  payment: {
    url: '/api/payment',
    name: "获取支付方式",
    post: async function(params){
      return await http.post(this.url, params);
    }
  },
  pay: {
    url: '/api/pay',
    name: "充值",
    post: async function(params){
      return await http.post(this.url, params);
    }
  },
  reOrder: {
    url: '/api/reOrder',
    name: "获取当面付结果",
    post: async function(params){
      return await http.post(this.url, params);
    }
  },
  incomeLogList: {
    url: '/api/incomeLogList',
    name: "余额记录",
    post: async function(params){
      return await http.post(this.url, params);
    }
  },
  questionList: {
    url: '/api/questionList',
    name: "常见问题",
    post: async function(params){
      return await http.post(this.url, params);
    }
  },
  questionDetail: {
    url: '/api/questionDetail',
    name: "常见问题详情",
    post: async function(params){
      return await http.post(this.url, params);
    }
  },
  announcedCategoryList: {
    url: '/api/announcedCategoryList',
    name: "工单类型列表",
    post: async function(params){
      return await http.post(this.url, params);
    }
  },
  ticketsList: {
    url: '/api/ticketsList',
    name: "工单列表",
    post: async function(params){
      return await http.post(this.url, params);
    }
  },
  addTickets: {
    url: '/api/addTickets',
    name: "添加工单",
    post: async function(params){
      return await http.post(this.url, params);
    }
  },
  announcedList: {
    url: '/api/announcedList',
    name: "公告列表",
    post: async function(params){
      return await http.post(this.url, params);
    }
  },
  announcedDetail: {
    url: '/api/announcedDetail',
    name: "公告详情",
    post: async function(params){
      return await http.post(this.url, params);
    }
  },
  peopleList: {
    url: '/api/peopleList',
    name: "我的下线",
    post: async function(params){
      return await http.post(this.url, params);
    }
  },
  substationsInfo: {
    url: '/api/substationsInfo',
    name: "分站信息",
    post: async function(params){
      return await http.post(this.url, params);
    }
  },
  buySubstations: {
    url: '/api/buySubstations',
    name: "购买分站",
    post: async function(params){
      return await http.post(this.url, params);
    }
  },
  urlList: {
    url: '/api/urlList',
    name: "主域名列表",
    post: async function(params){
      return await http.post(this.url, params);
    }
  },
  getDouYinMasterUrl: {
    url: '/api/getDouYinMasterUrl',
    name: "获取url",
    post: async function(params){
      return await http.post(this.url, params);
    }
  },
  getdouyinkuaishouid: {
    url: '/api/getdouyinkuaishouid',
    name: "获取id",
    post: async function(params){
      return await http.post(this.url, params);
    }
  },
  getSS: {
    url: '/api/GetSS',
    name: "获取说说id",
    post: async function(params){
      return await http.post(this.url, params);
    }
  },
  addOrder: {
    url: '/api/addOrder',
    name: "创建订单",
    post: async function(params){
      return await http.post(this.url, params);
    }
  },
  addCar: {
    url: '/api/addCar',
    name: "加入购物车",
    post: async function(params){
      return await http.post(this.url, params);
    }
  },
  carList: {
    url: '/api/carList',
    name: "购物车列表",
    post: async function(params){
      return await http.post(this.url, params);
    }
  },
  orderList: {
    url: '/api/orderList',
    name: "订单列表",
    post: async function(params){
      return await http.post(this.url, params);
    }
  },
  appRefund: {
    url: '/api/applyRefund',
    name: "申请退单",
    post: async function(params){
      return await http.post(this.url, params);
    }
  },
  orderDetail: {
    url: '/api/orderDetail',
    name: "订单详情",
    post: async function(params){
      return await http.post(this.url, params);
    }
  },
  editSubstationsPwd: {
    url: '/api/editSubstationsPwd',
    name: "修改分站密码",
    post: async function(params){
      return await http.post(this.url, params);
    }
  },
  noticeList: {
    url: '/api/noticeList',
    name: "时速通知",
    post: async function(params){
      return await http.post(this.url, params);
    }
  },
  notifyRed: {
    url: '/api/notifyRed',
    name: "时速通知红点",
    post: async function(params){
      return await http.post(this.url, params);
    }
  },
  substationsAuthList: {
    url: '/api/substationsAuthList',
    name: "分站权限",
    post: async function(params){
      return await http.post(this.url, params);
    }
  },
  resetKey: {
    url: '/api/resetKey',
    name: "重置Key",
    post: async function(params){
      return await http.post(this.url, params);
    }
  },
  tsy: {
    url: '/api/tsy',
    name: "去水印",
    post: async function(params){
      return await http.post(this.url, params);
    }
  },
  syTj: {
    url: '/api/syTj',
    name: "收益统计",
    post: async function(params){
      return await http.post(this.url, params);
    }
  },
}