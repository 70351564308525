const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/pc/Home.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/pc/Login.vue')
  },
  {
    path: '/forget',
    name: 'Forget',
    component: () => import('../views/pc/Forget.vue')
  },
  {
    path: '/class',
    name: 'Class',
    component: () => import('../views/pc/Class.vue')
  },
  {
    path: '/goodsDetail',
    name: 'GoodsDetail',
    component: () => import('../views/pc/GoodsDetail.vue')
  },
  {
    path: '/user',
    name: 'User',
    component: () => import('../views/pc/User.vue')
  },
  {
    path: '/collection',
    name: 'Collection',
    component: () => import('../views/pc/Collection.vue')
  },
  {
    path: '/order',
    name: 'Order',
    component: () => import('../views/pc/Order.vue')
  },
  {
    path: '/people',
    name: 'People',
    component: () => import('../views/pc/People.vue')
  },
  {
    path: '/website',
    name: 'Website',
    component: () => import('../views/pc/Website.vue')
  },
  {
    path: '/vip',
    name: 'Vip',
    component: () => import('../views/pc/Vip.vue')
  },
  {
    path: '/watermark',
    name: 'Watermark',
    component: () => import('../views/pc/Watermark.vue')
  },
  {
    path: '/balance',
    name: 'Balance',
    component: () => import('../views/pc/Balance.vue')
  },
  {
    path: '/balanceRecord',
    name: 'BalanceRecord',
    component: () => import('../views/pc/BalanceRecord.vue')
  },
  {
    path: '/question',
    name: 'Question',
    component: () => import('../views/pc/Question.vue')
  },
  {
    path: '/rechargeRecord',
    name: 'RechargeRecord',
    component: () => import('../views/pc/RechargeRecord.vue')
  },
  {
    path: '/notice',
    name: 'Notice',
    component: () => import('../views/pc/Notice.vue')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/pc/404.vue')
  }
]


export default routes
