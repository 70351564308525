<template>
  <div id="app">
    <router-view/>
    <div v-html="downHtml"></div>
  </div>
</template>

<script>

// import { mapMutations, mapActions } from 'vuex'
 
export default {
  data() {
    return {
      downHtml: ''
    }
  },
  mounted () {
    this.handleGetSetting()
  },
  methods: {
    async handleGetSetting() {
      var res = await this.$API.getSets.post({
        Key: 'header_html,down_html'
      })
      if (res.header_html) {
        let doc = new DOMParser().parseFromString(res.header_html, 'text/html')
        let script = doc.head.firstChild
        const dom = document.createElement('script')
        dom.type = script.type
        dom.src = script.src
        // dom.innerHTML = doc.head.firstChild.innerHTML
        document.head.appendChild(dom)
      }
      if (res.down_html) {
        this.downHtml = res.down_html
      }
    },
  }
}
</script>

<style lang="less">
input,
textarea {
  background: transparent;
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  resize: none;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
 
input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
a {
  text-decoration: none;
}
.van-loading,
.van-list__finished-text,
.van-list__error-text {
  text-align: center;
  color: #999;
}
.flex-1 {
  flex: 1;
}
.img-cover {
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.img-contain {
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
// PC端公共样式
.container {
  width: 100%;
  min-height: 100vh;
  font-size: 16px;
  background: url(./assets/pc-images/page-bg.png) no-repeat;
  background-size: 1920px auto;
  background-position-x: center;
  background-position-y: top;
  .public-content {
    position: relative;
    width: 1480px;
    margin: 0 auto;
  }
  .public-right-sidebar{
    padding: 20px 0 20px 208px;
    width: 1232px;
    min-height: calc(100vh - 107px);
  }
  input {
    font-size: 14px;
    color: #0b212c;
  }
  .empty-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
    .icon {
      width: 240px;
      height: 240px;
    }
    .name {
      line-height: 20px;
      font-size: 16px;
      color: #b3b3b3;
    }
  }
  .pagination-item {
    text-align: center;
  }
  .el-pagination {
    padding: 10px 20px 20px;
    &.is-background {
      .el-pager {
        li:not(.disabled) {
          &.active {
            background: linear-gradient( 90deg, #7683ff 0%, #6a5ffe 100%);
          }
          &:hover {
            color: #7683ff;
          }
        }
      }
    }
  }
  .table-item {
    .el-table {
      th.el-table__cell {
        font-size: 14px;
        font-weight: normal;
        background-color: #eff4ff;
      }
      td.el-table__cell, .el-table th.el-table__cell.is-leaf {
        border: 0;
        font-size: 13px;
      }
      .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
        background-color: #f3f5f8;
      }
    }
  }
}
// Wap端公共样式
.mobile-container {
  font-size: .28rem;
  input {
    font-size: .28rem;
    color: #151d2b;
  }
  a {
    color: #1399ff;
  }
  .van-popup--round {
    border-radius: .24rem;
    &.van-popup--bottom {
      border-radius: .24rem .24rem 0 0;
    }
  }
  .empty-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 1rem;
    .icon {
      width: 4.8rem;
      height: 4.8rem;
    }
    .name {
      line-height: .4rem;
      font-size: .28rem;
      color: #b3b3b3;
    }
  }
}
</style>
